import React from "react";
import Helmet from "react-helmet";

// components
import Layout from "../components/layout";
import Hero from "../components/home/Hero";
import Customers from "../components/Customers";
import ProductFeatures from "../components/home/ProductFeatures";
import Integrations from "../components/home/Integrations";
import Testimonials from "../components/home/Testimonials";

export default () => (
  <Layout>
    <Helmet>
      <meta
        name="description"
        content="Personably helps you build productive teams by creating amazing onboarding experiences for new hires. It lets you collaborate on the perfect process, and then automate the rest."
      />
    </Helmet>
    <Hero />
    <Customers />
    <ProductFeatures />
    <Integrations />
    <Testimonials />
  </Layout>
);
