import React from "react";
import { Curve, FlexSection, FillMargin } from "../layout";
import { H2 } from "@lewisblackwood/component-library";
import { ProfileCard } from "../../styledComponents/Common";

// Images
import Michael from "../../images/profile-michael.jpg";
import Gabriela from "../../images/profile-gabriela.jpg";
import Lucy from "../../images/profile-lucy.jpg";

const Testimonials = () => (
  <>
    <Curve colour="green" />
    <FillMargin colour="green" />
    <FlexSection colour="green" column="2">
      <H2 size="xlarge" sizeMobile="large" mb="xlarge" center>
        Teams using Personably
      </H2>
      <FlexSection
        alignItems="start"
        flexDirection="row"
        justifyContent="center"
        wrap
        style={{ margin: -10 }}
      >
        <ProfileCard
          image={Gabriela}
          name="Gabriela"
          role="People Partner"
          company="GoCardless"
          caseStudy=""
          info="“I love that I've now got an easy way to collaborate with our hiring managers in planning the onboarding for their new team members.”"
        />
        <ProfileCard
          image={Lucy}
          name="Lucy"
          role="People Operations"
          company="Cytora"
          caseStudy="/blog/using-automation-to-build-efficient-people-processes-at-cytora"
          info="“It’s set and forget, tasks never get missed and are no longer another thing on the to-do list. Personably proactively helps you with onboarding.”"
        />
        <ProfileCard
          image={Michael}
          name="Michael"
          role="People and Talent Coordinator"
          company="carwow"
          caseStudy="/blog/how-carwow-provide-a-consistent-welcome-for-every-new-hire"
          info="“Personably is like an Alfred to our Bruce Wayne. It creates new hires and schedules meetings in a flash, saving so many to-do lists and reminders.”"
        />
      </FlexSection>
    </FlexSection>
    <FillMargin right colour="green" />
    <Curve flipped colour="green" />
  </>
);

export default Testimonials;
