import React from "react";
import styled from "styled-components";
import { Card, FlexContainer } from "@lewisblackwood/component-library";
import media from "../Media";

export const IntegrationsContainer = styled(FlexContainer)`
  ${media.small`
    flex-direction: column;
  `};
`;

export const IntegrationsSubtitle = styled.div`
  width: 320px;
  text-align: center;

  @media only screen and (max-width: 1165px) {
    width: 240px;
  }

  @media only screen and (max-width: 766px) {
    width: 240px;
  }
`;

export const CircleBackground = styled.div`
  ${props => {
    const mediumWidth = parseInt(props.width.split("px")[0]) / 1.2;
    const smallWidth = parseInt(props.width.split("px")[0]) / 1.25;

    return `
      background-color: ${
        props.colour
          ? props.theme.colours[props.colour].lightest
          : props.theme.colours.white.default
      };
      width: ${props.width};
      height: ${props.width};
      border-radius: 100%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      position: relative;
      flex-direction: column;

      @media only screen and (max-width: 1165px) {
        width: ${mediumWidth}px;
        height: ${mediumWidth}px;
      }

      @media only screen and (max-width: 960px) {
        width: ${smallWidth}px;
        height: ${smallWidth}px;
      }
    `;
  }};
`;

export const MiddleCircle = styled(CircleBackground)`
  margin-left: -48px;
  z-index: 1;
  background-color: rgb(209, 249, 239, 0.6);

  @media only screen and (max-width: 960px) {
    margin-left: -80px;
  }

  ${media.small`
    margin: 0;
    margin-top: -32px;
  `};
`;

export const LastCircle = styled(CircleBackground)`
  margin-left: -48px;

  @media only screen and (max-width: 960px) {
    margin-left: -80px;
  }

  ${media.small`
    margin: 0;
    margin-top: -32px;
  `};
`;

const StyledIntegrationLogo = styled.img`
  height: ${props => props.theme.spacing.xlarge};
  width: auto;

  ${media.xsmall`
    height: 32px;
  `}

  ${media.xxsmall`
    height: ${props => props.theme.spacing.large};
  `}
`;

export const IntegrationLogo = props => (
  <Card css={{ margin: 10, padding: 16 }}>
    <StyledIntegrationLogo src={props.src} alt={props.alt} />
  </Card>
);

export const Arrow = styled.img`
  position: absolute;
  z-index: 3;
  transform: ${props =>
    props.firstArrow ? "translateX(-260%)" : "translateX(260%)"};

  ${media.medium`
    -webkit-transform: ${props =>
      props.firstArrow ? "translateX(-220%)" : "translateX(210%)"};
    -moz-transform: ${props =>
      props.firstArrow ? "translateX(-220%)" : "translateX(210%)"};
    transform: ${props =>
      props.firstArrow ? "translateX(-220%)" : "translateX(210%)"};
  `};

  ${media.small`
    -webkit-transform: rotate(90deg)
      ${props => (props.firstArrow ? "translateX(-220%)" : "translateX(220%)")};
    -moz-transform: rotate(90deg)
      ${props => (props.firstArrow ? "translateX(-220%)" : "translateX(220%)")};
    transform: rotate(90deg)
      ${props => (props.firstArrow ? "translateX(-220%)" : "translateX(220%)")};
  `};
`;
