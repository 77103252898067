import React, { Fragment } from "react";
import { keyframes } from "styled-components";
import ReactModal from "react-modal";

// Components
import { GridSection, TextSection, GridImageSection } from "../layout";
import { Image } from "../../styledComponents/product/Image";
import {
  H1,
  Text,
  LinkButton,
  modalStyles
} from "@lewisblackwood/component-library";
import { FloatingCircle, StyledCircle } from "../../styledComponents/home/Hero";
import { ProductVideo, LargePlayButton } from "../../styledComponents/Common";

// Images
import Screenshot from "../../images/hero-screenshot.svg";

const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
};

const animation = position => {
  const h = 35;

  const positions = {
    left: { x: { min: -50, max: -10 }, y: { min: -h, max: h } },
    right: { x: { min: 10, max: 42 }, y: { min: -h, max: h } },
    top: { x: { min: -30, max: -30 }, y: { min: h / 2, max: h } },
    bottom: { x: { min: -30, max: 30 }, y: { min: -h / 2, max: -h } }
  };

  const pos = positions[position];

  const firstX = getRandomInt(pos.x.min, pos.x.max);
  const firstY = getRandomInt(pos.y.min, pos.y.max);

  const pos25 = {
    x: getRandomInt(pos.x.min, pos.x.max),
    y: getRandomInt(pos.y.min, pos.y.max)
  };
  const pos50 = {
    x: getRandomInt(pos.x.min, pos.x.max),
    y: getRandomInt(pos.y.min, pos.y.max)
  };
  const pos75 = {
    x: getRandomInt(pos.x.min, pos.x.max),
    y: getRandomInt(pos.y.min, pos.y.max)
  };

  return keyframes`
    0% {
      transform: translate3d(${firstX}vw, ${firstY}vh, 0);
    }
    25% {
      transform: translate3d(${pos25.x}vw, ${pos25.y}vh, 0);
    }
    50% {
      transform: translate3d(${pos50.x}vw, ${pos50.y}vh, 0);
    }
    75% {
      transform: translate3d(${pos75.x}vw, ${pos75.y}vh, 0);
    }
    100% {
      transform: translate3d(${firstX}vw, ${firstY}vh, 0);
    }
  `;
};

export const HeroBackground = props => (
  <React.Fragment>
    <FloatingCircle animation={animation("left")} speed={getRandomInt(40, 60)}>
      <svg height="40" width="40">
        <StyledCircle fillColour="purple" />
      </svg>
    </FloatingCircle>
    <FloatingCircle animation={animation("right")} speed={getRandomInt(40, 60)}>
      <svg height="40" width="40">
        <StyledCircle fillColour="pink" />
      </svg>
    </FloatingCircle>
    <FloatingCircle animation={animation("left")} speed={getRandomInt(40, 60)}>
      <svg height="40" width="40">
        <StyledCircle fillColour="green" />
      </svg>
    </FloatingCircle>
    <FloatingCircle animation={animation("right")} speed={getRandomInt(40, 60)}>
      <svg height="40" width="40">
        <StyledCircle fillColour="purple" />
      </svg>
    </FloatingCircle>
    <FloatingCircle animation={animation("top")} speed={getRandomInt(40, 60)}>
      <svg height="40" width="40">
        <StyledCircle fillColour="pink" />
      </svg>
    </FloatingCircle>
    <FloatingCircle
      animation={animation("bottom")}
      speed={getRandomInt(40, 60)}
    >
      <svg height="40" width="40">
        <StyledCircle fillColour="green" />
      </svg>
    </FloatingCircle>
    <FloatingCircle animation={animation("top")} speed={getRandomInt(40, 60)}>
      <svg height="40" width="40">
        <StyledCircle fillColour="purple" />
      </svg>
    </FloatingCircle>
    <FloatingCircle
      animation={animation("bottom")}
      speed={getRandomInt(40, 60)}
    >
      <svg height="40" width="40">
        <StyledCircle fillColour="pink" />
      </svg>
    </FloatingCircle>
    {props.children}
  </React.Fragment>
);

class Hero extends React.Component {
  state = { isModalOpen: false };

  toggleModal = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  render() {
    return (
      <Fragment>
        <GridSection
          itemWidth="40vw"
          itemWidthLarge="32vw"
          itemWidthSmall="48vw"
          gap
          style={{ marginBottom: 80 }}
        >
          <HeroBackground />
          <GridImageSection
            rows="1fr auto 60px auto 1fr"
            columns="1fr auto 60px auto 1fr"
            onClick={this.toggleModal}
          >
            <LargePlayButton column="3 / 4" row="3 / 4" />
            <Image
              src={Screenshot}
              fit
              rounded
              shadow
              column="2 / 5"
              row="2 / 5"
            />
          </GridImageSection>
          <TextSection hero>
            <H1 size="xxlarge" sizeMobile="large">
              Smart onboarding for growing teams
            </H1>
            <Text size="xlarge" sizeMobile="large" mb="large">
              Create, automate and collaborate on the best experience for your
              new hires.
            </Text>
          </TextSection>
        </GridSection>

        <ReactModal
          isOpen={this.state.isModalOpen}
          onRequestClose={this.toggleModal}
          style={modalStyles({ width: "auto" })}
          ariaHideApp={false}
        >
          <ProductVideo />
        </ReactModal>
      </Fragment>
    );
  }
}

export default Hero;
