import styled, { css } from "styled-components";
import { HeroStrapline, fadeIn, fadeInSlow } from "../Common";
import media from "../Media";

export const ScreenshotImg = styled.img`
  border-radius: 5px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
  width: 540px;

  ${media.medium`
    width: 420px;
  `};

  @media only screen and (max-width: 900px) {
    width: 320px;
  }

  ${media.xsmall`
    width: 260px;
  `};
`;

const HeroContentStyling = css`
  display: inline-block;
  vertical-align: middle;

  ${media.small`
    text-align: center;
    width: 100%;
    margin-top: ${props => props.theme.spacing.medium};
  `};
`;

export const HeroContentLeft = styled.div`
  ${HeroContentStyling};
  width: 55%;
  text-align: center;
  ${fadeIn}
`;

export const HeroContentRight = styled.div`
  ${HeroContentStyling};
  width: 45%;
  padding-left: ${props => props.theme.spacing.medium};
  animation: ${fadeInSlow} 2s;

  ${media.small`
    padding-left: 0;
  `};
`;

export const HomePageStrapline = styled(HeroStrapline)`
  width: 90%;

  @media only screen and (max-width: 900px) {
    width: 100%;
  }
`;

export const FloatingCircle = styled.div`
  height: fit-content;
  width: fit-content;
  position: absolute;
  top: 320px;
  left: 50vw;
  z-index: -99;
  animation: ${props => props.animation} ${props => props.speed}s infinite;
`;

const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
};

export const StyledCircle = styled.circle.attrs(props => ({
  cx: "20",
  cy: "20",
  r: getRandomInt(10, 15),
  fill: props.theme.colours[props.fillColour].default
}))`
  /* stylelint-disable-line block-no-empty */
`;
