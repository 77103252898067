import React from "react";

// Components
import { Link } from "gatsby";
import { H2, Tag, Text, TextLink } from "@lewisblackwood/component-library";
import {
  TextSection,
  GridSection,
  GridImageSection,
  CircleBackground,
  Title
} from "../layout";
import { ImageAnimated } from "../../styledComponents/product/Image";

// images
import TaskTemplateCards from "../../images/product/task-template-cards.svg";
import EmailTaskComposer from "../ui/EmailTaskComposer";
import ScheduleCalendar from "../../images/calendar.svg";
import Checklist from "../../images/product/checklist.svg";
import {
  Timeline,
  Dot,
  TimelineTag
} from "../../styledComponents/home/ProductFeatures";

const ProductFeatures = () => (
  <GridSection columns="1fr">
    <Title column="1" row="1">
      <H2 size="xlarge" sizeMobile="large" mb="xxlarge" center>
        Deliver the best employee onboarding experience
      </H2>
    </Title>

    <Timeline column="1" row="2 / 9" />
    <Timeline column="1" row="2 / 5" mobileOnly />

    <TimelineTag row="3">Your new hire accepts their offer</TimelineTag>

    <GridSection
      column="1"
      row="4"
      columns="1fr minmax(64px, auto) 1fr"
      columnsMobile="1fr"
    >
      <TextSection column="1">
        <H2 size="xlarge" sizeMobile="large" colour="purple">
          Keep in touch with your hires effortlessly
        </H2>
        <Text size="xlarge" sizeMobile="large">
          Make them feel part of the team in that gap between accepting their
          offer and their first&nbsp;day.
        </Text>
        <Link to="/product/tasks" style={{ marginTop: 16 }}>
          <TextLink size="xlarge" sizeMobile="large" bold colour="purple">
            Read more about tasks
          </TextLink>
        </Link>
      </TextSection>

      <Dot colour="purple" />

      <GridImageSection
        columns="1fr auto auto auto 1fr"
        rows="1fr auto auto auto 1fr"
        column="3"
      >
        <EmailTaskComposer column="3" row="3" />
        <CircleBackground colour="purple" column="2 / 5" row="2 / 5" />
      </GridImageSection>
    </GridSection>

    <GridSection
      column="1"
      row="5"
      columns="1fr minmax(64px, auto) 1fr"
      columnsMobile="1fr"
      css={{ marginBottom: 0 }}
    >
      <GridImageSection
        columns="1fr auto auto auto 1fr"
        rows="1fr auto auto auto 1fr"
        column="1"
      >
        <ImageAnimated
          fitMobile
          src={TaskTemplateCards}
          column="3 / 3"
          row="3 / 3"
        />
        <CircleBackground colour="pink" column="2 / 5" row="2 / 5" />
      </GridImageSection>

      <Dot colour="pink" />

      <TextSection column="3">
        <H2 size="xlarge" sizeMobile="large" colour="pink">
          Have everything set up for their first day
        </H2>
        <Text size="xlarge" sizeMobile="large">
          Automatically add hires to the tools they need, ready for their
          arrival. Keep everyone internally in the loop.
        </Text>
        <Link to="/product/tasks" style={{ marginTop: 16 }}>
          <TextLink size="xlarge" sizeMobile="large" bold colour="pink">
            Read more about tasks
          </TextLink>
        </Link>
      </TextSection>
    </GridSection>

    <Timeline column="1" row="6 / 8" mobileOnly />
    <TimelineTag row="6">Your new hire joins your company</TimelineTag>

    <GridSection
      column="1"
      row="7"
      columns="1fr minmax(64px, auto) 1fr"
      columnsMobile="1fr"
    >
      <TextSection column="1">
        <H2 size="xlarge" sizeMobile="large" colour="green">
          Collaborate on training up new hires
        </H2>
        <Text size="xlarge" sizeMobile="large">
          Create and iterate on learning material, then automatically schedule
          sessions into calendars at the right time for each hire.
        </Text>
        <Link to="/product/scheduling" style={{ marginTop: 16 }}>
          <TextLink size="xlarge" sizeMobile="large" bold colour="green">
            Read more about scheduling
          </TextLink>
        </Link>
      </TextSection>

      <Dot colour="green" />

      <GridImageSection
        columns="1fr auto auto auto 1fr"
        rows="1fr auto auto auto 1fr"
        column="3"
      >
        <ImageAnimated fitMobile src={ScheduleCalendar} column="3" row="3" />
        <CircleBackground colour="green" column="2 / 5" row="2 / 5" />
      </GridImageSection>
    </GridSection>

    <GridSection
      column="1"
      row="8"
      columns="1fr minmax(64px, auto) 1fr"
      columnsMobile="1fr"
    >
      <GridImageSection
        columns="1fr auto auto auto 1fr"
        rows="1fr auto auto auto 1fr"
        column="1"
      >
        <ImageAnimated fitMobile src={Checklist} column="3 / 3" row="3 / 3" />
        <CircleBackground colour="pink" column="2 / 5" row="2 / 5" />
      </GridImageSection>

      <Dot colour="pink" />

      <TextSection column="3">
        <H2 size="xlarge" sizeMobile="large" colour="pink">
          Engage your hires from before they start
        </H2>
        <Text size="xlarge" sizeMobile="large">
          Remove some of the stress and uncertainty that comes with starting a
          new role. Let your hires know what to do and expect from their first
          few months.
        </Text>
        <Link to="/product/welcome-dashboard" style={{ marginTop: 16 }}>
          <TextLink size="xlarge" sizeMobile="large" bold colour="pink">
            Read more about the welcome dashboard
          </TextLink>
        </Link>
      </TextSection>
    </GridSection>
  </GridSection>
);

export default ProductFeatures;
