import React from "react";
import {
  FlexSection,
  ImageSection,
  CircleBackground,
  GridSection
} from "../layout";
import { H2, Text } from "@lewisblackwood/component-library";
import { IntegrationLogo } from "../../styledComponents/home/Integrations";

// images
import GoogleLogo from "../../images/integrations/logo-google.svg";
import GoogleCalendarLogo from "../../images/integrations/logo-google-calendar.svg";
import GmailLogo from "../../images/integrations/logo-gmail.svg";
import Office365Logo from "../../images/integrations/logo-office-365.svg";
import PeakonLogo from "../../images/integrations/logo-peakon.svg";
import PeopleLogo from "../../images/integrations/logo-people-hr.svg";
import SlackLogo from "../../images/integrations/logo-slack.svg";
import WorkableLogo from "../../images/integrations/logo-workable.svg";

const Integrations = () => (
  <FlexSection column="2">
    <H2 size="xlarge" sizeMobile="large" mb="xsmall" center>
      Power up the tools you use
    </H2>
    <Text
      size="xlarge"
      sizeMobile="large"
      mb="xlarge"
      center
      style={{ maxWidth: 720 }}
    >
      Automatically import details about your hires and team members from your
      existing tools. Use that to set up sessions, accounts and email templates.
    </Text>
    <GridSection
      columns="1fr repeat(3, auto) 1fr"
      rows="1fr auto 1fr"
      css={{ margin: 0 }}
    >
      <ImageSection
        wrap
        css={{ maxWidth: 1080, margin: "-10px auto" }}
        column="2 / 5"
        columnMobile="2 / 5"
        row="2"
      >
        <IntegrationLogo src={GoogleLogo} alt="Google G Suite" />
        <IntegrationLogo src={Office365Logo} alt="Microsoft Office 365" />
        <IntegrationLogo src={WorkableLogo} alt="Workable" />
        <IntegrationLogo src={SlackLogo} alt="Slack" />
        <IntegrationLogo src={GoogleCalendarLogo} alt="Google Calendar" />
        <IntegrationLogo src={GmailLogo} alt="Gmail" />
        <IntegrationLogo src={PeakonLogo} alt="Peakon" />
        <IntegrationLogo src={PeopleLogo} alt="People HR" />
      </ImageSection>
      <CircleBackground colour="purple" column="3" row="1 / 4" small />
    </GridSection>
  </FlexSection>
);

export default Integrations;
